// 升级续费
// mxy
<template>
  <div class="iview flex-column" :style="{ minHeight: minHeight + 'px' }">
    <header>可以根据您公司与业务需求，选择相应套餐</header>
    <div class="card flex-column">
      <a-row>
        <a-col v-for="item of versionList" :span="8" :key="item.id">
          <div class="item">
            <a-card hoverable style="cursor: pointer">
              <img class="title" :src="item.appletBackgroundImg" />
              <div class="level">
                {{ item.versionName }} ({{ item.versionMembers }})
              </div>
              <div class="center">
                <div :title="item.description" class="text">
                  {{ item.description }}
                </div>
                <div class="flex-centent">
                  <div :class="item.membersDiscount === 0 ? 'prices' : ''">
                    <span>{{ item.priceUnit }}</span>
                    <span
                      v-if="item.membersDiscount !== 0"
                      style="font-size: 28px; font-weight: 600"
                      >{{
                        item.versionPrice * item.membersDiscount.toFixed(2)
                      }}</span
                    >
                    <span
                      v-if="item.membersDiscount === 0"
                      style="font-size: 28px; font-weight: 600"
                      >{{ item.versionPrice }}</span
                    >
                    <span>/年</span>
                  </div>
                  <div
                    v-if="item.membersDiscount !== 0"
                    :class="item.membersDiscount !== 0 ? 'price' : ''"
                  >
                    <span style="color: #ababab"
                      >{{ item.priceUnit }}{{ item.versionPrice }}/年</span
                    >
                  </div>
                </div>
                <div v-if="item.membersDiscount !== 0" class="flex-centent">
                  <div class="discount">
                    <span> {{ item.membersDiscount * 10 }}</span>
                    <span>折</span>
                  </div>
                  <div style="color: #ababab; font-size: 12px">
                    <span style="margin-left: 2px">优惠立省</span>
                    <span
                      >{{
                        item.versionPrice -
                        item.versionPrice * item.membersDiscount.toFixed(2)
                      }}元</span
                    >
                  </div>
                </div>
              </div>
              <div class="footer">
                <a-button type="primary" @click="pay(item)">联系购买</a-button>
                <a-modal
                  title="购买信息"
                  :visible="visible"
                  cancelText="取消"
                  okText="确认"
                  :maskClosable="false"
                  :confirm-loading="confirmLoading"
                  @ok="handleOk"
                  @cancel="handleCancel"
                >
                  <a-spin :spinning="spinning">
                    <a-form
                      :model="register"
                      ref="formRef"
                      :rules="rules"
                      autocomplete="off"
                      :labelCol="{ span: 6 }"
                      :wrapperCol="{ span: 18 }"
                    >
                      <a-form-item required name="name" label="姓名">
                        <a-input
                          style="width: 80%"
                          size="large"
                          :maxLength="30"
                          type="text"
                          v-model:value="register.name"
                          placeholder="请输入"
                        >
                        </a-input>
                      </a-form-item>
                      <a-form-item required name="phoneNumber" label="手机号">
                        <a-input
                          style="width: 80%"
                          size="large"
                          :maxLength="11"
                          type="text"
                          v-model:value="register.phoneNumber"
                          placeholder="请输入"
                        >
                        </a-input>
                      </a-form-item>
                      <a-form-item required name="companyName" label="企业名称">
                        <a-input
                          style="width: 80%"
                          size="large"
                          :maxLength="30"
                          type="text"
                          v-model:value="register.companyName"
                          placeholder="请输入"
                        >
                        </a-input>
                      </a-form-item>
                    </a-form>
                  </a-spin>
                </a-modal>
              </div>
            </a-card>
          </div>
        </a-col>
      </a-row>
    </div>
    <div class="table">
      <div class="flex flex-row justify-between">
        <div :key="item" v-for="item in service" class="flex-column">
          <span class="title">{{ item.title }}</span>
          <div
            class="container"
            :style="{ color: e == null ? 'red' : '' }"
            :key="e"
            v-for="e in item.serviceArray"
          >
            {{ e == null ? "X" : e }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue'
import api from '@/api/API'
import { minheight } from './components/sameVariable'
import { getProfileDetail } from '@/api/IdentityAPI'
export default defineComponent({
  components: {
    // 'usage-record': UsageRecord,
  },
  data () {
    return {
      // ModalText: 'Content of the modal',
      visible: false,
      confirmLoading: false,
      rules: {
        name: [
          {
            required: true,
            message: '请输入姓名',
            trigger: 'blur',
          },
        ],
        phoneNumber: [
          {
            required: true,
            message: '请输入手机号',
            trigger: 'blur',
          },
          {
            len: 11,
            message: '请输入正确的11位手机号码',
            trigger: 'blur',
          },
        ],
        companyName: [
          {
            required: true,
            message: '请输入公司名称',
            trigger: 'blur',
          },
        ],
      }, // 验证规则
      register: {
        name: '',
        phoneNumber: '',
        companyName: '',
      },
      versionList: [],
      service: [],
      serviceList: [],
      versionName: '',
    }
  },
  // 页面加载事件
  created () {
    this.minHeight = minheight
  },
  mounted () {
    this.getEdition()
    this.getInformation()
  },
  // 方法
  methods: {
    // 获取账户信息
    getInformation () {
      getProfileDetail().then(data => {
        if (data.status === 1) {
          this.$message.error('当前账户已被禁用，如有疑问请联系运维人员')
          window.location.href = '/account/login'
          window.localStorage.clear()
        }
        this.register.name = data.name
        this.register.phoneNumber = data.phoneNumber
      })
      const tenantList = JSON.parse(localStorage.getItem('tenantList'))
      this.register.companyName = tenantList.enterpriseName
    },
    // 获取版本信息
    getEdition () {
      api
        .get('/api/app/sys-ent-version/sell-version-and-service')
        .then(({ data }) => {
          this.versionList = data.versionList
          this.service = data.service
          const serviceLists = data.service
          serviceLists.forEach(element => {
            this.serviceList = element.serviceArray
          })
          this.spinning = false
        })
        .catch(err => {
          this.spinning = false
          // this.$message.error(err)
          console.log(err)
        })
    },
    // 联系购买
    pay (item) {
      this.visible = true
      this.versionName = item.versionName
    },
    // 确定
    handleOk () {
      this.$refs.formRef.validate().then(() => {
        this.confirmLoading = true
        this.spinning = true
        const obj = {
          name: this.register.name,
          phone: this.register.phoneNumber,
          companyName: this.register.companyName,
          versionName: this.versionName,
        }
        api
          .post('/api/app/message/purchase-version', obj)
          .then(({ data }) => {
            this.confirmLoading = false
            this.spinning = false
            this.$message.success('已成功提交购买信息')
            this.handleCancel()
          })
          .catch(err => {
            this.confirmLoading = false
            this.spinning = false
            // this.$message.error('创建失败' + err)
            console.log(err)
          })
      })
    },
    // 取消
    handleCancel () {
      this.visible = false
      this.$refs.formRef.resetFields()
    },
  },
})
</script>

<style  lang="scss" scoped>
@import "@/assets/common.scss";
.iview {
  width: 1160px;
  max-width: 1160px;
  padding: 10px 120px 30px;
  header {
    text-align: center;
    height: 40px;
    line-height: 40px;
    opacity: 1;
    font-size: 30px;
    font-weight: 400;
    color: #333333;
    padding: 40px;
  }
  .item {
    margin: 20px 10px 10px;
    padding: 10px;
    .title {
      width: 100%;
      height: 120px;
    }
    .level {
      display: block;
      position: relative;
      top: -70px;
      left: 20px;
      font-size: 16px;
      color: #fff;
    }
    .center {
      padding: 0 18px 8px 18px;
      .text {
        font-size: 12px;
        height: 56px;
        -webkit-line-clamp: 3;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .price {
        text-align: center;
        padding: 17px 0;
        text-decoration: line-through;
        font-size: 12px;
        margin: 10px 0 0 6px;
      }
      .prices {
        text-align: center;
        padding: 9px 0 31px 0;
      }
    }
    .footer {
      text-align: center;
      margin: 10px 0 30px;
    }
  }
  .discount {
    width: 40px;
    height: 22px;
    text-align: center;
    line-height: 22px;
    color: #fff;
    font-size: 12px;
    background: url(../../assets/youhui.svg) no-repeat;
    background-size: cover;
  }
  .table {
    margin: 10px 20px;
    padding: 20px 40px;
    background-color: #fff;
    text-align: center;
    .title {
      padding: 20px 0;
      font-size: 16px;
      font-weight: 800;
    }
    .container {
      padding: 20px 0;
    }
  }
}
.operation {
  width: 50%;
  margin: 10px 0 0 75px;
  .btn {
    margin-right: 40px;
  }
}
::v-deep(.ant-card) {
  .ant-card-body {
    padding: 0;
  }
}
// ::v-deep(.ant-btn) {
//   border-color: #134ccf;
//   background-color: #134ccf;
// }
// ::v-deep(.ant-btn:hover, .ant-btn:focus) {
//   color: #fff;
//   opacity: 0.7;
// }

.ant-card-bordered {
  border-radius: 3%;
}
.ant-modal-footer {
  padding: 30px 16px !important;
}
</style>
